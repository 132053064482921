<template>
  <div class="recharge-wrap">
   
    <div class="content">
      <div class="info-desc">
        <div class="info-title">小额交易建议您使用快捷充值，即时到账。线下汇款一般5-10分钟到账，最晚不超过24小时，请您耐心等待。</div>
      </div>
      <div class="info-desc">
        <div class="info-title">请按如下信息汇款：</div>
      </div>
      <div class="info">
        <div class="info-title">开户名称</div>
        <div class="info-content">北京无觅科技有限公司</div>
      </div>
      <div class="info">
        <div class="info-title">开户银行</div>
        <div class="info-content">中国农业银行北京阜成门支行</div>
      </div>
      <div class="info">
        <div class="info-title">银行账户</div>
        <div class="info-content">11210601040013253</div>
      </div>
      <!--<div class="info">
        <div class="info-title">为了方便您使用信息，可将其发送至：</div>
        <div class="info-content">
          <el-input v-model='amount' size="mini" placeholder='请输入手机号码' style='width: 150px' clearable >{{this.phoneNumber}}
          </el-input>  
          <el-button
            class="verification-btn"
            slot="append"
            size="small"
            :disabled="verifibtn"
            @click="getverificate"
            >{{ vervalue ? vervalue : "点击发送" }}</el-button
          > 
        </div>
      </div>-->
      <div class="info2">
        <div class="info-title">若已完成汇款，请在下方选填汇款金额，点击提交按钮，加速充值进度。</div>
      </div>
      <div class="info">
        <div class="info-title">汇款金额（选填）：</div>
        <div class="info-content">
          <el-input v-model='amount' size="mini" placeholder='请输入汇款金额' style='width: 150px' clearable />&nbsp&nbsp元        
        </div>
       
      </div>
      <div class="info">
        <div class="info-title">
            <el-button type="primary"
                 size="small"
                 @click="submit">提交</el-button>       
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "@/utils/navigation";
import { Message } from 'element-ui';
import { mapGetters } from "vuex";
export default {
  data () {
    return {
        amount: "",
        verifibtn: false,
        vervalue: "",
        phoneNumber: "",
    };
  },
  mounted() {
    this.$store.dispatch("user/getuserinfo").then((res) => {
      if (res.RetCode == 0) {
        this.phoneNumber = res.PhoneNumber;
      }
    });
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
  methods: {
    getverificate() {
        this.verifibtn = true;
        var vartime = 60;
        var timer = setInterval(() => {
          vartime--;
          this.vervalue = `${vartime}s`;
          if (vartime == 0) {
            clearInterval(timer);
            this.verifibtn = false;
            this.vervalue = "";
          }
        }, 1000);
        var payload = {
          PhoneNumber: "",
        };
        this.$store.dispatch("user/sendvertification", { ...payload });
    },
    submit(){
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持充值，请登录主账号进行操作~"
        );
        return
      }
      if (this.amount == '' || this.amount == 0) {
        this.$message.warning(
          "请输入汇款金额"
        );
        return
      }
      var amount = {
          Amount: this.amount * 100,
        };
      this.$store.dispatch("user/settransferreminder", { ...amount });
      this.$message({
          showClose: true,
          message: '提交成功，请您耐心等待~',
          showClose: false,
          type: 'success'
        });
      this.amount = ""
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
