var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recharge-wrap"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_vm._v("汇款金额（选填）：")]),_c('div',{staticClass:"info-content"},[_c('el-input',{staticStyle:{"width":"150px"},attrs:{"size":"mini","placeholder":"请输入汇款金额","clearable":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_vm._v("  元 ")],1)]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.submit}},[_vm._v("提交")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-desc"},[_c('div',{staticClass:"info-title"},[_vm._v("小额交易建议您使用快捷充值，即时到账。线下汇款一般5-10分钟到账，最晚不超过24小时，请您耐心等待。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-desc"},[_c('div',{staticClass:"info-title"},[_vm._v("请按如下信息汇款：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_vm._v("开户名称")]),_c('div',{staticClass:"info-content"},[_vm._v("北京无觅科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_vm._v("开户银行")]),_c('div',{staticClass:"info-content"},[_vm._v("中国农业银行北京阜成门支行")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_vm._v("银行账户")]),_c('div',{staticClass:"info-content"},[_vm._v("11210601040013253")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info2"},[_c('div',{staticClass:"info-title"},[_vm._v("若已完成汇款，请在下方选填汇款金额，点击提交按钮，加速充值进度。")])])
}]

export { render, staticRenderFns }