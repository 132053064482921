<template>
  <div class="recharge-wrap">
    <div class="bread-wrap">
      <span class="bread-title">账户充值</span>
    </div>
    
    <div class="content">
      <el-tabs v-model="activeName" style="margin-left: 20px; margin-right: 20px" >
      <el-tab-pane label="快捷充值" name="online">
      <on-line/>
      </el-tab-pane>
      <el-tab-pane label="银行汇款充值" name="offline">
      <off-line/>
      </el-tab-pane>
    </el-tabs>
    </div>
    
      
  </div>
</template>
<script>
import navigation from "@/utils/navigation";
import OnLine from "./OnLine/index.vue";
import OffLine from "./OffLine/index.vue";


export default {
  data () {
    return {
      activeName: "online",
    };
  },
  methods: {
    
  },
  mounted () {
  },
  components: {  OnLine , OffLine},


};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
