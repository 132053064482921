<template>
  <div class="recharge-wrap">
    
    <div class="content">
      <div class="info">
        <div class="info-title">当前余额：</div>
        <div class="info-content">{{ balance.toFixed(2) }} 元</div>
      </div>
      <div class="info">
        <div class="info-title">充值金额：</div>
        <div class="info-content">
          <el-input-number :min="0"
                           :controls="false"
                           :max="1000000"
                           :precision="2"
                           @input="inputTotal"
                           size="small"></el-input-number>
          &nbsp;元
        </div>
      </div>
      <div class="info">
        <div class="info-title">支付方式：</div>
        <div class="info-content">
          <div class="pay-type"
               :class="{active: payform.PayType == 'wechatpay_native'}" @click="changePayType('wechatpay_native')">
            <i class="iconfont icon-weixinzhifu"></i>
            <div class="pay-text">微信支付</div>
          </div>
          <div class="pay-type"
               :class="{active: payform.PayType == 'alipay_page'}" @click="changePayType('alipay_page')">
            <img src="@/assets/icon-alipay.png" class="icon-alipay" />
            <div class="pay-text">支付宝支付</div>
          </div>
        </div>
      </div>
      <el-button type="primary"
                 size="small"
                 class="pay-button"
                 @click="navorderconfirm">立即支付</el-button>
    </div>
  </div>
</template>
<script>
import navigation from "@/utils/navigation";
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      balance: 0,
      payform: {
        Total: 0,
        PayType: 'wechatpay_native'
      }
    };
  },
  methods: {
    navorderconfirm () {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持充值，请登录主账号进行操作~"
        );
        return
      }
      if (this.payform.Total == 0) {
        this.$message({
          type: "error",
          message: "金额不能为0！",
        });
        return
      }
      navigation.navigateTo({
        url: 'rechargeconfirm',
        params: {
          payload: {
            ...this.payform,
          },
        },
      });
    },
    inputTotal (val) {
      if (val != undefined) {
        this.payform.Total = val
      }
    },
    getUserBalance () {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
    changePayType (payType) {
      this.payform.PayType = payType
    }
  },
  mounted () {
    this.getUserBalance()
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
